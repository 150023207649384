<template>
  <div v-on:click="$emit('close')" v-if="isOpen" id="myModal" class="modal">
    <div v-on:click.stop class="modal-content">
      <span>
        <i v-on:click.stop="$emit('close')" class="fa fa-times close" aria-hidden="true"></i>

        <div style="float:left;" class="appendices-container">
          <label class="file-select">
            <div class="select-button">
              <span>{{ $t("appendices.selectFiles") }}</span>
            </div>
            <input
              class="btn btn-secondary"
              :ref="'files'"
              type="file"
              @input="handleFileChange"
              multiple
            >
          </label>
        </div>
      </span>
      <table class="file-list">
        <thead>
          <tr>
            <th>{{ $t("invoiceForm.name") }}</th>
            <th class="numeric">{{ $t("appendices.size") }}</th>
            <th>{{ $t("appendices.type") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, index) in appendices.files" :key="index">
            <td v-bind:data-title="$t('invoiceForm.name')">{{file.file.name}}</td>
            <td v-bind:data-title="$t('appendices.size')">{{parseFloat(file.file.size) / 1000}} kb</td>
            <td v-bind:data-title="$t('appendices.type')">{{file.file.type}}</td>
            <td>
              <button
                :disabled="file.loading"
                v-on:click="removeFile(file.file)"
                class="btn btn-success loading-btn"
              >
                {{ file.loading === false ? $t("common.delete") : ''}}
                <i v-if="file.loading" class="fa fa-spinner fa-spin"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { mapActions } from "vuex";
import moment from "moment";
const { mapFields } = createHelpers({
  getterType: "invoice/getField",
  mutationType: "invoice/updateField"
});

export default {
  props: ["isOpen"],
  data() {
    return {
      open: false,
      files: []
    };
  },
  mounted: function() {},
  computed: {
    ...mapFields([
      "appendices",
      "userData.TransferKey",
      "userData.TransferID",
      "invoiceField.billingInfo"
    ])
  },
  methods: {
    ...mapActions("invoice", [
      "updateAppendices",
      "createAppendixStorage",
      "listAppendices",
      "deleteAppendix",
      "uploadAppendix",
      "saveForm"
    ]),

    removeFile(fileToRemove) {
      this.appendices.files.find(
        file => file.file.name === fileToRemove.name
      ).loading = true;
      this.deleteAppendix({
        tid: this.TransferID,
        tkey: this.TransferKey,
        storageId: this.appendices.StorageID,
        storageKey: this.appendices.StorageKey,
        fileName: fileToRemove.name
      }).then(response => {
        if (response.Status === "OK" && response.StatusCode === "2400") {
          this.appendices.files = this.appendices.files.filter(item => {
            return item.file.name !== fileToRemove.name;
          });
        }
        this.$refs.files.value = "";
        this.saveForm();
      });
    },
    handleFileChange(e) {
      if (e) {
        if (!this.appendices.StorageID) {
          let time = moment().format("YYYYMMDDHHmmss");
          let bid = this.billingInfo.businessId;
          let name = bid.replace("-", "") + "-" + time;
          this.createAppendixStorage({
            tid: this.TransferID,
            tkey: this.TransferKey,
            name
          }).then(value => {
            if (e.target.files.length > 1) {
              for (let i = 0; e.target.files.length > i; i++) {
                this.uploadFile(e.target.files[i]);
              }
            } else {
              this.uploadFile(e.target.files[0]);
            }
          });
        } else {
          if (e.target.files.length > 1) {
            for (let i = 0; e.target.files.length > i; i++) {
              this.uploadFile(e.target.files[i]);
            }
          } else {
            this.uploadFile(e.target.files[0]);
          }
        }
      }
    },
    uploadFile(file) {
      if (!this.appendices.files.find(item => item.file.name === file.name)) {
        let mediaType = file.type;
        let fileName = file.name;
        this.appendices.files.push({ file, loading: true });
        this.uploadAppendix({
          tid: this.TransferID,
          tkey: this.TransferKey,
          storageId: this.appendices.StorageID,
          mediaType,
          fileName,
          file
        }).then(response => {
          if (response.Status === "OK" && response.StatusCode === "2600") {
            let index = this.appendices.files.findIndex(
              item => item.file.name === file.name
            );
            this.appendices.files[index].loading = false;
            this.saveForm();
          } else {
            let index = this.appendices.files.findIndex(
              item => item.file.name === file.name
            );
            this.appendices.files.splice(index, 1)
            this.saveForm();
          }
          this.$refs.files.value = "";
        }).catch(error => {
          console.log(error)
            let index = this.appendices.files.findIndex(
              item => item.file.name === file.name
            );
            this.appendices.files.splice(index, 1)
            this.saveForm();
            this.$refs.files.value = "";

        })
      }
    }
  }
};
</script>

<style scoped>
/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 10% 10% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  max-width: 650px; /* Could be more or less, depending on screen size */
  box-shadow: 5px 5px 15px  hsla(0, 0%, 0%, 0.4);
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.file-list {
  max-width: 600px;
  text-align: left;
  margin-top: 1em;
}
.file-list tbody {
  display: block;
  overflow: auto;
  height: 200px;
}
.file-list thead tr {
  display: block;
}

.file-list > li {
  position: relative;
  padding: none;
}
.file-select {
  margin: 0 0 0 0;
}
.file-select > .select-button {
  padding: 1rem;

  color: white;
  background-color: gray;
  width: 12em;

  border-radius: 0.3rem;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}
.loading-btn {
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 7px 14px; /* Some padding */
  font-size: 16px; /* Set a font-size */
  width: 100px;
}

.file-select:hover > .select-button {
  background-color: rgb(155, 155, 134);
  border-radius: 0.3rem 0.3rem 0 0;
}

.file-select > input[type="file"] {
  display: none;
}
@keyframes loading-spinner {
  to {
    transform: rotate(360deg);
  }
}
.loading:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 100%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: loading-spinner 0.6s linear infinite;
}
@media screen and (min-width: 768px) {
  .file-list tbody {
    width: 600px;
  }
  .file-list td {
    width: 200px;
  }
  .file-list th {
    width: 130px;
  }
}
@media screen and (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: 800px;
    margin: 15% auto;
  }
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }
  .file-list tbody {
    height: 600px;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  /*
	Label the data
	*/
  td:before {
    content: attr(data-title);
  }
}
</style>
